<template>
    <div class="">
        <div>
            <modal width="80%" sm:width="50%" height="auto" name="example">
                <ShortageList />
            </modal>
            <div
                class="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 lg:px-2 xl:px-0"
            >
                <div class="mb-4 sm:mb-0">
                    <h1 class="text-2xl sm:text-4xl">Dashboard</h1>
                    <p class="text-sm text-gray-400">
                        Hi {{ user.data.name }}, Welcome to your dashboard
                    </p>
                </div>

                <div
                    class="sm:mt-0 flex flex-col items-start gap-x-2 sm:items-end justify-start sm:justify-start"
                >
                    <button @click="shortageList()" class="mb-2 sm:mb-0">
                        <div class="font-bold text-red-600 text-lg">
                            Total Shortage:
                            <span v-if="stats">
                                <small>GMD </small>{{ stats.total_shortage }}
                            </span>
                            <span v-else>...</span>
                        </div>
                    </button>
                    <div
                        class="font-bold text-green-600 text-lg sm:text-xl text-left sm:text-right"
                    >
                        Online Users:
                        <span v-if="onlineUseres > 0">{{ onlineUseres }}</span>
                        <span v-else>...</span>
                    </div>
                </div>
            </div>

            <!-- ./Statistics Cards -->
            <div
                v-if="stats"
                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-9"
            >
                <div class="bg-white w-auto p-6 rounded-lg shadow-md">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Total Sales</h2>
                    </div>

                    <div>
                        <h1>
                            GMD <b>{{ formatPrice(stats.amount) }}</b>
                        </h1>
                    </div>
                </div>

                <div class="bg-white w-auto p-6 rounded-lg shadow-md">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Total Transactions</h2>
                    </div>

                    <div>
                        <h1>
                            <b>{{ stats.transactions }}</b>
                        </h1>
                    </div>
                </div>

                <div class="bg-white w-auto p-6 rounded-lg shadow-md">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Total Commission</h2>
                    </div>

                    <div class="flex">
                        <div class="pr-2">
                            <h1>
                                GMD <b>{{ formatPrice(stats.commission) }}</b>
                            </h1>
                        </div>
                    </div>
                </div>

                <div class="bg-white w-auto p-6 rounded-lg shadow-md">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Collection Reconcile</h2>
                    </div>

                    <div class="flex">
                        <div class="pr-2 border-r">
                            <h1>
                                GMD
                                <b>{{
                                    formatPrice(stats.cash_collection_today)
                                }}</b>
                            </h1>
                        </div>

                        <div class="px-2 border-r">
                            <h1 class="text-red-500">
                                <b>{{
                                    formatPrice(stats.non_reconcile_amount)
                                }}</b>
                            </h1>
                        </div>

                        <div class="px-2">
                            <h1 class="text-red-500">
                                <b>{{ stats.non_reconcile_count }}</b>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ./Statistics Cards -->
            <div
                v-if="agentsSales"
                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-9"
            >
                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Agents Total Sales</h2>
                    </div>

                    <div>
                        <h1>
                            GMD <b>{{ formatPrice(agentsSales.amount) }}</b>
                        </h1>
                    </div>
                </div>

                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Agents Total Transactions</h2>
                    </div>

                    <div>
                        <h1>
                            <b>{{ agentsSales.transactions }}</b>
                        </h1>
                    </div>
                </div>

                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Agents Total Commission</h2>
                    </div>

                    <div class="flex">
                        <div class="pr-2">
                            <h1>
                                GMD
                                <b>{{
                                    formatPrice(
                                        (agentsSales.commission * 75) / 100
                                    )
                                }}</b>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Chapman Total Commission</h2>
                    </div>

                    <div class="flex">
                        <div class="pr-2">
                            <h1>
                                GMD
                                <b>{{
                                    formatPrice(
                                        (agentsSales.commission * 25) / 100
                                    )
                                }}</b>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="superagentsSales"
                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-9"
            >
                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Superagents Total Sales</h2>
                    </div>

                    <div>
                        <h1>
                            GMD
                            <b>{{ formatPrice(superagentsSales.amount) }}</b>
                        </h1>
                    </div>
                </div>

                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Superagents Total Transactions</h2>
                    </div>

                    <div>
                        <h1>
                            <b>{{ superagentsSales.transactions }}</b>
                        </h1>
                    </div>
                </div>

                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Superagents Total Commission</h2>
                    </div>

                    <div class="flex">
                        <div class="pr-2">
                            <h1>
                                GMD
                                <b>{{
                                    formatPrice(
                                        superagentsSales.agent_commission / 100
                                    )
                                }}</b>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="bg-white w-auto p-6 rounded-lg border">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Chapman Total Commission</h2>
                    </div>

                    <div class="flex">
                        <div class="pr-2">
                            <h1>
                                GMD
                                <b>{{
                                    formatPrice(
                                        superagentsSales.chapman_commission /
                                            100
                                    )
                                }}</b>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                <div class="bg-white w-full sm:w-auto p-6 rounded-lg shadow-md">
                    <div class="flex justify-between mb-4">
                        <h2 class="text-lg">Branches Sales</h2>

                        <select
                            v-model="filterDate"
                            @change="loadData"
                            class="text-gray-500"
                        >
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="week">This week</option>
                            <option value="month">This Month</option>
                            <option value="last_month">Last Month</option>
                        </select>
                    </div>
                    <div>
                        <BranchSalesChart
                            :filterDate="filterDate"
                            :results="results"
                            :labels="labels"
                        />
                    </div>
                </div>

                <div class="w-full sm:w-auto">
                    <div class="bg-white rounded-lg pb-6 shadow">
                        <div v-if="vendorInfo" class="p-6 flex justify-between">
                            <div>
                                <h2 class="uppercase">Total Balance</h2>
                                <h1 class="text-3xl my-2">
                                    GMD {{ formatPrice(vendorInfo.balance) }}
                                </h1>
                            </div>
                        </div>

                        <div class="flex flex-col">
                            <div
                                class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
                            >
                                <div
                                    class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                                >
                                    <div class="flex w-full justify-between">
                                        <div class="w-full">
                                            <ul v-if="accounts.length > 0">
                                                <li
                                                    v-for="(
                                                        account, i
                                                    ) in accounts"
                                                    :key="i"
                                                    class="w-full"
                                                >
                                                    <div
                                                        class="flex justify-between px-6 py-6 whitespace-nowrap"
                                                    >
                                                        <div>
                                                            <span
                                                                class="text-xl font-bold text-green-600"
                                                                >GMD
                                                                {{
                                                                    formatPrice(
                                                                        account.balance
                                                                    )
                                                                }}</span
                                                            ><br />

                                                            <span
                                                                class="text-sm text-gray-500"
                                                            >
                                                                {{
                                                                    account.name
                                                                }}
                                                            </span>
                                                        </div>

                                                        <div
                                                            v-if="
                                                                account.id === 2
                                                            "
                                                            class="text-right"
                                                        >
                                                            <span
                                                                class="text-xl font-bold text-green-600"
                                                                >GMD
                                                                {{
                                                                    formatPrice(
                                                                        stats
                                                                            ? stats.agents_balance
                                                                            : 0
                                                                    )
                                                                }}</span
                                                            ><br />
                                                            <span
                                                                class="text-sm text-gray-500"
                                                            >
                                                                Agents available
                                                                balance
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Branch Sales -->
            <div class="bg-white w-auto p-6 rounded-lg shadow-sm my-9">
                <h1 class="text-xl my-4">Branch Sales</h1>
                <BranchSales :showHeader="false" :data="branchSales" />
            </div>
            <!-- End Branch Sales -->

            <!-- Pending Workheet -->
            <div class="bg-white w-auto p-6 rounded-lg shadow-sm">
                <h1 class="text-xl my-4">Pending Workheets</h1>
                <PendingWorksheet
                    :user="user"
                    :Loading="Loading"
                    :showHeader="false"
                    :data="pendingWorksheets"
                    @clearPendingWorksheet="clearPendingWorksheet"
                />
            </div>
            <!-- End Pending Workheet -->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BranchSalesChart from '../../components/Charts/BranchSalesChart';
import AgentsBalance from '@/mixins/AgentsBalance';
import TxAgentsBalance from '@/mixins/TxAgentsBalance';
import FormatPrice from '@/mixins/FormatPrice';
import VendorInfo from '@/mixins/VendorInfo';
import checkPermissionExist from '../../mixins/checkPermissionExist';

import PendingWorksheet from '../Reports/Docs/PendingWorksheet';
import BranchSales from '../Reports/Docs/BranchSales';
import ShortageList from './components/ShortageList';

export default {
    middleware: 'auth',

    mixins: [
        AgentsBalance,
        VendorInfo,
        TxAgentsBalance,
        FormatPrice,
        checkPermissionExist,
    ],

    layout: 'default',

    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),

        totalAccountsBalance: function () {
            let total = [];

            Object.entries(this.accounts).forEach(([key, val]) => {
                console.log(key);
                total.push(val.balance); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },
    },

    components: {
        PendingWorksheet,
        BranchSalesChart,
        ShortageList,
        BranchSales,
    },

    data: () => ({
        accounts: [],
        Loading: false,
        stats: null,
        branchSales: [],
        onlineUseres: 0,
        agentsSales: null,
        superagentsSales: null,
        filterDate: 'today',

        results: [
            { name: 'Sales', values: [] },
            { name: 'Commission', values: [] },
        ],
        labels: [],

        pendingWorksheets: [],
    }),

    created() {},

    mounted() {
        if (!this.checkPermissionExist(this.user.data, 'dashboard-read')) {
            this.$router.push({ path: `/myprofile` });
            // checking something...

            return false;
        } else {
            this.loadBalance();
            this.loadStats();
            this.loadData();
            this.loadOnlineUsers();
            this.loadAgentsData();
            this.loadSuperAgentsData();
            this.loadPendingWorksheets();
        }
    },

    methods: {
        shortageList() {
            this.$modal.show('example');
        },

        clearPendingWorksheet() {
            this.loadPendingWorksheets();
        },

        async loadOnlineUsers() {
            // Submit the form

            this.hasPermission();

            try {
                const { data } = await axios.get(`/online_users`);

                this.onlineUseres = data;

                // this.isLoading = false;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;
                return false;
            }
        },

        async loadPendingWorksheets() {
            this.Loading = true;

            this.hasPermission();

            try {
                const { data } = await axios.get(`/pending_worksheets`);

                this.pendingWorksheets = data.data;

                // this.isLoading = false;
            } catch (e) {
                console.log('Error');
                this.Loading = false;
                return false;
            }
        },

        async loadData() {
            // Submit the form

            this.hasPermission();

            try {
                const { data } = await axios.get(
                    `/dashboard/branch/sales?filterDate=${this.filterDate}`
                );

                this.branchSales = data;
                this.result = data;

                let amtData = [];
                let comData = [];

                let labels = [];

                Object.entries(this.result).map(item => {
                    labels.push(`${item[0]}`);
                });

                Object.entries(this.result).map(item => {
                    console.log('ITEM IS SET DATA', item[1].amount);
                    amtData.push(item[1].amount);
                });

                Object.entries(this.result).map(item => {
                    console.log('ITEM IS SET DATA', item[1].commission);
                    comData.push(item[1].commission);
                });

                this.labels = labels;

                this.results[0].values = amtData;
                this.results[1].values = comData;

                // this.isLoading = false;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;
                return false;
            }
        },

        async loadBalance() {
            // console.log("LLLLLLLL");
            this.hasPermission();

            try {
                const { data } = await axios.get(`/accounts`);

                this.accounts = data.data;

                return true;
            } catch (e) {
                console.log('Error', e);
                return false;
            }
        },

        async loadStats() {
            // console.log("LLLLLLLL");
            this.hasPermission();
            try {
                const { data } = await axios.get(`/dashboard/stats`);

                this.stats = data;

                return true;
            } catch (e) {
                console.log('Error', e);
                return false;
            }
        },

        async loadBranchSales() {
            this.hasPermission();
            try {
                const { data } = await axios.get(`/dashboard/branchSales`);

                this.branchSales = data;

                return true;
            } catch (e) {
                console.log('Error', e);
                return false;
            }
        },

        async loadAgentsData() {
            // Submit the form

            this.hasPermission();

            try {
                const { data } = await axios.get(`/dashboard/agents/sales`);

                this.agentsSales = data;

                // this.isLoading = false;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;
                return false;
            }
        },

        async loadSuperAgentsData() {
            this.hasPermission();

            try {
                const { data } = await axios.get(
                    `/dashboard/superagents/sales`
                );

                this.superagentsSales = data;

                // this.isLoading = false;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;
                return false;
            }
        },

        hasPermission() {
            if (!this.checkPermissionExist(this.user.data, 'dashboard-read')) {
                return null;
            }
        },
    },
};
</script>
